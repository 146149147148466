import axios from "axios";

export async function getBudgets(page, filters, pageSize, sorter) {
  const { data } = await axios.get(`/tenant/budgets.json`, {
    params: {
      page,
      per_page: pageSize,
      "q[s]": sorter,
      ...filters,
    },
  });
  return data;
}

export async function getSingleBudget(id) {
  const { data } = await axios.get(`/tenant/budgets/${id}.json`, {});
  return data;
}
export async function getDateRanges(id,type,) {
  const { data } = await axios.get(`/tenant/budgets/${id}/date_ranges.json?cycle=${type}`, {});
  return data.date_ranges;
}

export async function getBudgetsLastReference() {
  const { data } = await axios.get(`/tenant/budgets/next_code.json`);
  return data;
}

export async function getAccounts() {
  const { data } = await axios.get(`/tenant/accounts.json?nested=true`);
  return data;
}
export async function getBudgetsPermissions() {
  const { data } = await axios.get(`/tenant/budgets/permissions.json`);
  return data;
}

export async function getCategories() {
  const { data } = await axios.get(`/tenant/categories.json?nested=true`);
  return data;
}
export async function getAssetClassifications() {
  const { data } = await axios.get(`/tenant/asset_classifications.json`);
  return data;
}

export async function postBudget(formData) {
  try {
    const response = await axios.post("/tenant/budgets.json", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export async function postBudgetDetails(formData , id) {
  try {
    const response = await axios.patch(`/tenant/budgets/${id}.json`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
