import React from 'react'
import {ConfigProvider} from 'antd'
import token from 'themes/light.json';

const antdWithTheme = (Component) => (React.forwardRef((props, ref) => (
    <ConfigProvider theme={token}>
        <Component {...props} ref={ref} />
    </ConfigProvider>
)))

export default antdWithTheme