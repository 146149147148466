// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HhCM1uYsuNiTkcivTKAf>span+span{flex:1 !important}\n", "",{"version":3,"sources":["webpack://./app/javascript/styles/layout.scss"],"names":[],"mappings":"AAAA,gCAEQ,iBAAkB","sourcesContent":[".q_ant_menu__sub_item {\n    > span + span {\n        flex: 1 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"q_ant_menu__sub_item": "HhCM1uYsuNiTkcivTKAf"
};
export default ___CSS_LOADER_EXPORT___;
