import { useTranslation } from "react-i18next";
import i18next from "i18next";
import React from "react";
import { Button, Input } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";

export const useTableColumns = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("budgets.attributes.reference"),
      dataIndex: "code",
      key: "code",
      sorter: true,
    },
    {
      title: t("budgets.attributes.name"),
      dataIndex: i18next.language === "en" ? "name_en" : "name_ar",
      key: i18next.language === "en" ? "name_en" : "name_ar",
      sorter: true,
    },
    {
      title: t("budgets.attributes.budget_type"),
      dataIndex: "kind",
      key: "kind",
      sorter: true,
    },
    {
      title: t("budgets.attributes.fiscal_year"),
      dataIndex: "fiscal_year",
      key: "fiscal_year",
      sorter: true,
    },
    {
      title: t("budgets.attributes.actions"),
      key: "actions",
      render: (text, record) => (
        <Button
          icon={<EyeOutlined />}
          href={`/tenant/budgets/${record.id}`}
          style={{ marginRight: 8 }}
        />
      ),
    },
  ];

  return {
    columns,
  };
};
export const useBudgetDetailsColumns = (dateRanges, cycle, formData, handleInputChange) => {
  const { t } = useTranslation();
  const renderEditableInput = (record, field) => {
    const isDisabled = record.children && record.children.length > 0;    
    return (
      <Input
        style={{ width: cycle.no === 12 ? "130px" : "150px" }}
        value={formData[record.id]?.[field] || 0}
        onChange={(e) => handleInputChange(e, record.id, field)}
        disabled={isDisabled}
      />
    );
  };

  const columns = [
    {
      title: t("budgets.show.budget_details.codes"),
      dataIndex: "code",
      key: "id",
      width: 180,
    },
    {
      title: t("budgets.show.budget_details.name"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("budgets.show.budget_details.budget_amount"),
      dataIndex: "budget_amount",
      key: "budget_amount",
      render: (_, record) => (
        <Input
          style={{ width: cycle.no === 12 ? "130px" : "150px" }}
          value={formData[record.id]?.budget_amount || 0}
          disabled
        />
      ),
    },
    ...(Array.isArray(dateRanges)
      ? dateRanges.map((year, i) => {
          const date = moment(year, "D-MM-YYYY");
          const fiscalYear = date.year();
          const shortName = date.format("MMM");
          let title;
          if (dateRanges.length === 4) {
            const startShortName = moment(year[0], "D-MM-YYYY").format("MMM");
            const endShortName = moment(year[1], "D-MM-YYYY").format("MMM");
            title = `${startShortName}-${endShortName}.${fiscalYear}`;
          } else {
            title = `${shortName} ${fiscalYear}`;
          }
          return {
            title: title,
            dataIndex: i + 1,
            key: `${i + 1}_${fiscalYear}`,
            width: cycle.no === 12 ? 100 : 200,
            render: (_, record) => renderEditableInput(record, i + 1),
          };
        })
      : []),
  ];

  return columns;
};


