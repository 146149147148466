import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QPageLayout from 'components/layout/QPageLayout';
import QEmptyIndex from 'components/layout/emptyIndex';
import EmptyDimensionIcon from 'assets/icons/empty-dimension.svg'
import { Button, Table, Space, TableProps, Tooltip, Spin, Flex, message, Form, Input } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, PlusOutlined, SettingOutlined, UndoOutlined } from '@ant-design/icons';
import { useQuery } from 'react-query';
import axios from 'axios';
import i18next from 'i18next';
import { SorterResult } from 'antd/es/table/interface';

export const useBreadCrumbData = () => {
  const { t } = useTranslation();
  return [
      {
          title: t('activerecord.attributes.layout.settings'),
      },
      {
          title: t('activerecord.attributes.dimension.name'),
      }
  ]
}

interface DataType {
  id: string;
  code: string;
  name_en: string;
  name_ar: string;
  description: string;
}

const LOCALIZED_VALUES = new Map([['name', {en: 'name_en', ar: 'name_ar'}]])
const SORT_DIRECTION = new Map([['ascend', 'asc'], ['descend', 'desc']])

const getSorterKey = (key) => {
  if(LOCALIZED_VALUES.has(key)) {
    return LOCALIZED_VALUES.get(key)[i18next.language]
  }
  return key;
}

const useColumns = (): TableProps<DataType>['columns'] => {
  const { t } = useTranslation();

  return ([
    {
      title: t('activerecord.attributes.dimension.index.code'),
      dataIndex: 'code',
      width: '12.5%',
      sorter: true,
      showSorterTooltip: false
    },
    {
      title: t('activerecord.attributes.dimension.index.name'),
      width: '37.5%',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        i18next.language === 'en'? record.name_en : record.name_ar
      )
    },
    {
      title: t('activerecord.attributes.dimension.index.description'),
      dataIndex: 'description',
      width: '37.5%'
    },
    {
      title: t('activerecord.attributes.dimension.index.actions.title'),
      key: 'action',
      width: '12.5%',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip placement="top" title={t('activerecord.attributes.dimension.index.actions.add_value')}>
            <Button href={`/tenant/dimensions/${record.id}/values/new`} icon={<PlusOutlined />} />
          </Tooltip>
        </Space>
      ),
    },
  ])
} 


const DimensionIndex = ({}) => {
  const { t } = useTranslation();
  const columns = useColumns();
  const breadCrumbData = useBreadCrumbData();
  const [page, setPage] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [ form ] = Form.useForm();
  const [ filterUpdated, setFilterUpdate ] = useState<undefined | boolean>(undefined);
  const [ sorter, setSorter ] = useState({field: undefined, order: undefined});
  const [ filterApplied, setFilterApplied ] = useState(false);
  const [ sortApplied, setSortApplied ] = useState(false);

  const onChange: TableProps<DataType>['onChange'] = useCallback((pagination, _, sorter) => {
    setPage(pagination.current);
    const { order, field } = sorter as SorterResult<DataType>;
    setSorter({field: getSorterKey(field as string), order: SORT_DIRECTION.get(order)})
    if(field) {
      setSortApplied(true);
    } else {
      setSortApplied(false);
    }
  }, [])

  const { data, isLoading, isError, isFetching, isPreviousData } = useQuery(['dimensions', page, filterUpdated, sorter], async () => {
    return (await axios.get(`/tenant/dimensions.json?page=${page}&q[code_cont]=${form.getFieldValue('code') ?? ''}&q[name_ar_or_name_en_cont]=${form.getFieldValue('name') ?? ''}&q[s]=${sorter.field ?? ''}+${sorter.order ?? ''}`)).data
  }, { keepPreviousData: true });

  useEffect(() => {
    if(isError) {
      messageApi.open({
        type: 'error',
        content: t('forms.messages.error')
      });
    }
  }, [ isError ])
  
  let actionButtons = null;  

  if(data?.pagination.total_entries > 0) {
    actionButtons = (
      <Space>
        <Button icon={<PlusOutlined />} type="primary" href='/tenant/dimensions/new'>{t('activerecord.attributes.dimension.index.actions.add')}</Button>
      </Space>
    )
  }
  
  return (
    <QPageLayout title={t('activerecord.attributes.dimension.name_plural')} 
      actionBtns={actionButtons}
      breadCrumbData={breadCrumbData} messageHolder={ contextHolder }>
      {
        isLoading && !isPreviousData? 
          <Flex style={{ height: '5em' }} align='center' justify='center'><Spin /></Flex> 
          :
          data?.pagination.total_entries || sortApplied || filterApplied || isFetching?
            (
              <>
                <Form
                  form={form}
                  name="code"
                  onFinish={() => {}}
                  layout='inline'
                >
                  <Form.Item
                    name="code"
                  >
                    <Input maxLength={25} placeholder={t('activerecord.attributes.dimension.index.code')} />
                  </Form.Item>
                  <Form.Item
                    name="name"
                  >
                    <Input maxLength={25} placeholder={t('activerecord.attributes.dimension.index.name')} />
                  </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button icon={<FilterOutlined />} onClick={() => { setFilterUpdate(state => !state); setFilterApplied(true)}} type="primary">{t('forms.buttons.filter')}</Button>
                      <Button onClick={() => { form.resetFields(); setFilterUpdate(state => !state); setFilterApplied(false) }} icon={<UndoOutlined />} danger>{t('forms.buttons.reset')}</Button>
                    </Space>
                  </Form.Item>
                </Form>
                <Table rowKey="id" loading={isFetching} columns={columns} dataSource={data?.records} pagination={{total: data?.pagination.total_entries, current: data?.pagination.current_page, pageSize: 15}} onChange={onChange} />
              </>
            ) : (
              <QEmptyIndex title={t('activerecord.attributes.dimension.index.title')} subTitle={t('activerecord.attributes.dimension.index.sub_title')} Icon={ EmptyDimensionIcon }>
                <Button icon={<PlusOutlined />} type="primary" href='/tenant/dimensions/new'>{t('activerecord.attributes.dimension.index.actions.add')}</Button>
              </QEmptyIndex>
            )
      }
    </QPageLayout>
  )
}

export default DimensionIndex