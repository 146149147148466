import React from 'react'

import { theme, Layout } from 'antd';
import QPageHeader from './QPageHeader';
import QContent from './QContent';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb'

const { useToken } = theme;

interface QPageLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  breadCrumbData: ItemType[]; 
  actionBtns: React.ReactNode | React.ReactNode[];
  title: string;
  messageHolder?: React.ReactNode
}

const QPageLayout: React.FC<QPageLayoutProps> = ({children, breadCrumbData, actionBtns, title, messageHolder}) => {

  const { token } = useToken();
  
  return (
    <Layout style={{ paddingInline: token.paddingLG }}>
      {messageHolder}
      <QPageHeader breadCrumbData={breadCrumbData} >
        {actionBtns}
      </QPageHeader>
      <QContent title={title}>
        {children}
      </QContent>
    </Layout>
)
}

export default QPageLayout