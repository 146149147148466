type TransformedData = {
  value: number;
  label: string;
  title: string;
  children?: TransformedData[];
};

type Category = {
  id: number;
  name: string;
  children?: Category[];
};

interface TreeNode {
  title: string;
  value: string | number;
}

export const transformData = (data: Category[]) => {
  return data?.map((item) => {
    const transformedItem: TransformedData = {
      value: item.id,
      label: item.name,
      title: item.name,
    };
    if (item.children && item.children.length > 0) {
      transformedItem.children = transformData(item.children);
    }
    return transformedItem;
  });
};

export const filterTreeNode = (inputValue: string, treeNode: TreeNode) => {
  return (
    treeNode.title.toLowerCase().includes(inputValue.toLowerCase()) ||
    treeNode.value == inputValue
  );
};

export const getBudgetIdFromUrl = () => {
  const urlParts = window.location.pathname.split("/");
  return urlParts[urlParts.length - 1];
};

export const calculateBudgetAmount = (values) => {
  return values.reduce((sum, val) => sum + val, 0);
};

export const addInitialData = (data, cycle, initialData, parentId = null) => {
  data.forEach((account) => {
    const values = account.budgeting_values?.values || [];
    initialData[account.id] = {
      ...account,
      ...Object.fromEntries(
        Array.from({ length: cycle.no }, (_, i) => [
          i + 1,
          values[i] || 0,
        ])
      ),
      budget_amount: calculateBudgetAmount(values),
      children: account.children.map((child) => child.id),
      parentId,
    };

    if (account.children.length) {
      addInitialData(account.children, cycle, initialData, account.id);
    }
  });
};

export const updateParentNodes = (formData, cycle) => {
  Object.keys(formData).forEach((key) => {
    const record = formData[key];
    if (record.parentId) {
      let parentRecord = formData[record.parentId];
      if (parentRecord) {
        parentRecord.budget_amount = parentRecord.children.reduce(
          (sum, childId) => {
            const childRecord = formData[childId];
            return sum + (childRecord ? childRecord.budget_amount : 0);
          },
          0
        );

        for (let i = 1; i <= cycle.no; i++) {
          parentRecord[i] = parentRecord.children.reduce(
            (sum, childId) => {
              const childRecord = formData[childId];
              return sum + (childRecord ? childRecord[i] || 0 : 0);
            },
            0
          );
        }

        formData[record.parentId] = parentRecord;
      }
    }
  });
};

export const resetDateToZero = (formData, cycle) => {
  const resetFormData = {};
  Object.keys(formData).forEach((key) => {
    resetFormData[key] = {
      ...formData[key],
      budget_amount: 0,
      ...Object.fromEntries(
        Array.from({ length: cycle.no }, (_, index) => [index + 1, 0])
      ),
    };
  });
  return resetFormData;
};

export const updateExpandedRowKeys = (expanded, record, expandedRowKeys) => {
  if (expanded) {
    return [...expandedRowKeys, record.id];
  } else {
    return expandedRowKeys.filter((key) => key !== record.id);
  }
};


